export default {
  your: 'vos',
  favs: 'favs',
  kofi: 'Offrez-moi un café',
  helmet: {
    title: 'Flow State - De la musique de jeux vidéo pour se concentrer.',
    description:
      'Entrez dans un état de flow en écoutant des mixes de musique de jeux vidéo et des ambiances sonores atmosphériques',
  },
  homepage: {
    description: 'Mixes musicaux de jeux vidéo',
    chooseChannel: 'Choisissez le genre de musique',
    chooseAmbientSound: `Choisissez le fond sonore`,
    start: 'Démarrer',
  },
  settings: {
    channel: 'Genre musical',
    musicVolume: 'Volume de la musique',
    ambientSound: 'Fond sonore',
    ambientSoundVolume: 'Volume du fond sonore',
  },
  player: {
    track: 'Titre',
    changeMix: 'Prochain mix {{mood}}',
  },
};
