export default {
  your: 'your',
  favs: 'favs',
  kofi: 'Buy me a coffee',
  helmet: {
    title: 'Flow State - Video Game Music To Focus',
    description:
      'Enter a state of flow and focus by listening to quality video game music mixes and ambient noises.',
  },
  homepage: {
    description: 'Video game music mixes',
    chooseChannel: 'Choose musical genre',
    chooseAmbientSound: `choose ambient soundscape`,
    start: 'Start',
  },
  settings: {
    channel: 'Musical genre',
    musicVolume: 'Music volume',
    ambientSound: 'Ambient soundscape',
    ambientSoundVolume: 'Soundscape volume',
  },
  player: {
    track: 'Track',
    changeMix: 'next {{mood}} mix',
  },
};
